import React, { useState, useEffect } from 'react';
import './order-details-main.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/footer';
import { getSingleOrder } from '../../shared/apis';
import { useParams } from "react-router-dom";
import pathimg from '../../assets/images/path.png'

import moment from 'moment';
function OrderDetailsMain() {
    const [order, setOrder] = useState([]);
    const { id } = useParams();


    const init = async () => {
        let Order = await getSingleOrder(id);
        setOrder(Order.data.order);
    };

    useEffect(() => {
        init();

    }, []);

    return (
        <div className='order-details-main-bg'>
            <Navbar />
            <div className='order-details-main'>
                <div className="order-main-card">
                    <div className="card-body">
                        <h2 className='order-headings'>Order Details</h2>
                        <p className='order-subheadings' >Previous orders details</p>
                        <hr />
                        <div class='path-img-container'>
                            <img className='path-img' src={pathimg} alt="" />
                            <p className='orderdetail-uname'>{order?.chef?.restaurantName}</p>
                        </div>

                        <hr />
                        <div className="ordersdetail-card">
                            <div className="ordersdetail-content">
                                <div className='past-orders-left'>
                                    <img src={order?.chef?.coverPhoto} className="order-detail-btm" />
                                    <div className="orders-body-content">
                                        <p className='orderdetail-title'>{order?.chef?.restaurantName}:</p>
                                        <p className='orderdetails-time'>{moment(order.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="lower-detail-cards">
                    <div className="order-main-card-2">
                        <div className="card-body">
                            <p className='order-summary-title' >Order Summary:</p>
                            <hr />
                            {order?.orderItems?.map((item) => (
                                // <div className='dish-charges'>
                                //     <p className='past-orders-details'>{item.quantity}x {item.dish.dishName}</p>
                                //     <p className='charges' id='charges' > ${item.dish.price} </p>
                                // </div>
                                <div className='dish-charges' key={item.id}>
                                    <div className='dish-details'>
                                        <p className='past-orders-details'>{item.quantity}x {item.dish.dishName}</p>
                                    </div>
                                    <div className='charges'>
                                        <p>Rs.{item.dish.price}</p>
                                    </div>
                                </div>
                            ))}
                            <hr />

                            <div className='subtotal'>
                                <p className='subtotal-deading'>Subtotal</p>
                                <p>Rs.{order.subTotal?.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="order-main-card-3">
                        <div className="card-body">
                            <p className='order-summary-title' >Total Invoice:</p>
                            <hr />
                            <div className='charges-container'>
                                <div className='charge'>
                                    <p>Subtotal</p>
                                    <p>Rs.{order.subTotal?.toFixed(2)}</p>
                                </div>
                                <div className='charge'>
                                    <p>Delivery Fee</p>
                                    <p>Rs.{order.deliveryFee}</p>
                                </div>
                                <div className='charge'>
                                    <p>Service Fee</p>
                                    <p>Rs.{order.serviceFee}</p>
                                </div>
                            </div>
                            <hr />
                            <div className='subtotal'>
                                <p className='subtotal-deading' >Total</p>
                                <p>{order.total?.toFixed(2)}</p>
                            </div>

                            {/* <div className='reorder-div'>
                                <button className='reorder-btn'>Reorder</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OrderDetailsMain
