import React, { useState, useEffect } from 'react';
import './Autocompletend.css';
import { getMe, placeDetail, updateUser, autoComplete } from '../../../shared/apis';
import { v4 as uuid } from 'uuid';
import ReactAutocomplete from 'react-autocomplete';

function Autocompletend() {
    const [User, setUser] = useState([]);
    const [Adress, setAdress] = useState("Location...");
    const [items, setitems] = useState([]);
    const [uuid4, setuuid4] = useState('');
    const [value, setValue] = useState('');

    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
        if (user.data.user.streetAddress) {
            setAdress(user.data.user.streetAddress)
        }
        setuuid4(uuid())
    }

    useEffect(() => {
        init();
    }, []);


    // const handleOnSelect = async (item) => {
    //     let place = await placeDetail(item.placeId, uuid4)
    //     let body = {
    //         streetAddress: place.data.places.result.formatted_address,
    //         location: {
    //             type: "Point",
    //             coordinates: [place.data.places.result.geometry.location.lng, place.data.places.result.geometry.location.lat]
    //         },
    //         address: {
    //             street: place.data.places.result.formatted_address,
    //         }
    //     }
    //     await updateUser(User._id, body);
    // }
    const handleOnSelect = async (item) => {
        // console.log(items);
        try {
            // Find the item in the array that matches the selected item's name
            const matchedItem = items.find(selecteditem => selecteditem.name === item);
            // console.log(matchedItem);
            setValue(matchedItem.name)

            if (!matchedItem) {
                console.error("No matching item found.");
                return;
            }

            let place =  await placeDetail(matchedItem.placeId, uuid4);

            // if (!place || !place.data || !place.data.places || !place.data.places.result) {
            //     console.error("Invalid place data received:", place);
            //     return;
            // }

            let body = {
                streetAddress: place.data.places.result.formatted_address,
                location: {
                    type: "Point",
                    coordinates: [place.data.places.result.geometry.location.lng, place.data.places.result.geometry.location.lat]
                },
                address: {
                    street: place.data.places.result.formatted_address,
                }
            };
            localStorage.setItem('UserAddress', JSON.stringify(matchedItem.name));
            setAdress(body.streetAddress);

            await updateUser(User._id, body);

        } catch (error) {
            console.error("Error while handling selection:", error);
        }
    };



    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const handleValue = async (event) => {
        const typedValue = event.target.value;
        // console.log(typedValue);
        setValue(typedValue);
        let test = []
        let result = await autoComplete(typedValue, uuid4);
        for (let i = 0; i < result?.data?.places?.predictions.length; i++) {
            test.push({
                id: i,
                name: result?.data?.places?.predictions[i]?.description,
                placeId: result?.data?.places?.predictions[i]?.place_id,
            })
        }
        setitems(test);
    }


    return (
        <div>
            <ReactAutocomplete
                items={items}
                shouldItemRender={(item, inputValue) =>
                    item.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                }
                getItemValue={item => item.name}
                renderItem={(item, highlighted) => (
                    <div
                        key={item.id}
                        style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}
                    >
                        {item.name}
                    </div>
                )}
                value={value}
                onChange={handleValue}
                onSelect={(item) => handleOnSelect(item)}
                inputProps={{ className: 'autocomplete-input' }}
            />

        </div>
    )
}

export default Autocompletend;
