import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slider.css";
import Voice from '../../../assets/images/Voice.jpg'
import Gift from '../../../assets/images/Gift.jpg'
import FpSucess from '../../../assets/images/third.jpeg'

const SimpleSlider = () => {
  return (
    <Carousel
      showArrows={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={3000}
      showThumbs={false}
      showStatus={false}
      transitionTime={500}
    >
      <a href="/blog/Spreading-Joy-&-Gift-at-a-Time">
        <div className="slider-images">
          <img src={Gift} className="images" />
        </div>
      </a>
      <a href="/blog/Voice-Command-Food-Ordering">
        <div className="slider-images">
          <img src={Voice} className="images" />
        </div>
      </a>
      <a href="/blog/ForunPoint-Blueprint-for-Success">
        <div className="slider-images">
          <img src={FpSucess} className="images" />
        </div>
      </a>
    </Carousel>
  );
};

export default SimpleSlider;
