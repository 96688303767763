import React from 'react'
// import '../firstpage.css'
import Gift from '../../../../assets/images/Gift.jpg'

function secondpage() {
    return (
        <div>
            <div className="firstpage">
                <div className="first-page-img">
                    <img src={Gift} alt="Gifts" />
                </div>
                <div className="first-page-title">
                    <h1>ForunPoint Spreading Joy, One Gift at a Time</h1>
                </div>
                <div className="first-page-content">
                    <p>

                        In a world where distance often separates us from the ones we love, ForunPoint's gift-giving feature is a beacon of warmth and connection. With just a few clicks, you can send the perfect gift straight to your loved one's doorstep, no matter where they are in Lahore. Whether it's a flavourful meal, a fragrant bouquet of flowers, indulgent chocolates, or a scrumptious cake, ForunPoint is here for all your gifting needs. This article discusses the following topics:
                        <br />
                        <br />
                        <ul>
                            <li>When to Use <br />
                            </li>
                            <li>How to Send Gifts <br />
                            </li>
                            <li>Benefits of the feature<br />
                            </li>
                            <li>Unique Features <br />
                            </li>

                        </ul>
                    </p>
                    <h2>Make it Memorable</h2>
                    <p>

                        Picture this: It's your mom's birthday, and you want to surprise her with something special. But you're miles away and can't make it home in time. Instead of feeling helpless, you turn to ForunPoint's gift-giving feature. You browse through our wide selection of gifts, from mouthwatering meals to delightful desserts, and select the perfect treat for your mom. With the option to add a personalised message, you pour your heart out in a heartfelt video, letting your mom know just how much she means to you.
                        <br />
                        But ForunPoint's gift-giving feature isn't just for birthdays, it's perfect for any occasion. Whether you want to congratulate a friend on their promotion, send condolences to a grieving loved one, or simply say "I love you," ForunPoint has you covered. Not only can you send gifts within Lahore, but now, you can also send gifts from anywhere in Pakistan or the US to your loved ones in Lahore. With our seamless ordering process and reliable delivery service, you can rest assured that your gift will arrive fresh, on time, and bursting with flavour.

                    </p>

                    <h2>Start Gifting Today!</h2>
                    <p>


                        So why wait? Spread joy, love, and happiness with ForunPoint's gift-giving feature today. With the ability to send food, flowers, chocolates, and cakes, the possibilities are endless. Whether you're near or far, you can make every moment special, so why wait? Indulge in the art of gifting with ForunPoint today, and let your loved ones know they're always in your heart.


                    </p>
                </div>
            </div>
        </div>
    )
}

export default secondpage
