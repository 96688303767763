import "./sub-footer.css";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SubFooter() {
    const navigate = useNavigate();
    const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
    const showToastFunction = () => {
        toast.error('Please enter your address before proceeding!', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };
    const handleRestaurantClick = (event, city, area, restaurant) => {
        event.preventDefault();
        if (!userAddress) {
            showToastFunction();
        } else {
            const encodedRestaurant = encodeURIComponent(restaurant);
            navigate(`/restaurants/city/${city}/area/${area}/${encodedRestaurant}`);
        }
    };
    const handleAreaClick = (event, city, area) => {
        event.preventDefault();
        if (!userAddress) {
            showToastFunction();
        } else {
            navigate(`/restaurants/city/${city}/area/${area}`);
        }
    };
    const handleGiftClick = (event, city, restaurant) => {
        event.preventDefault();
        if (!userAddress) {
            showToastFunction();
        } else {
            navigate(`/restaurants/city/${city}/${restaurant}`);
        }
    };
    const handleBlogClick = (event, blog) => {
        event.preventDefault();
        if (!userAddress) {
            showToastFunction();
        } else {
            navigate(`/blog/${blog}`);
        }
    };


    // const handleCityClick = (city) => {
    //     navigate(`/restaurants/city/${city}`);
    // };
    return (
        <div className="section-footer">
            {/* Left side image (F1) */}


            <footer className="footer-bs">
                {/* <hr className="subfooter-hr" /> */}

                <div className="picture-section">
                    <div className="category">
                        <h4>AMERICAN</h4>
                        <ul>
                            {/* <li><a href="#" onClick={(e) => handleRestaurantClick('Lahore', 'Gulberg', 'Turkish Fried Chicken')}>Turkish Fried Chicken - Gulberg</a></li> */}
                            <li>
                                <a href={`/restaurants/city/Lahore/area/Gulberg/Turkish%20Fried%20Chicken`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Turkish Fried Chicken')}>
                                    Turkish Fried Chicken - Gulberg
                                </a>
                            </li>

                            <li><a href={`/restaurants/city/Lahore/area/Model%20Town/Johny%20%26%20Bunty%20Foods`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Model Town', 'Johny & Bunty Foods')}>Johny & Bunty Foods - Model Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/La%20Down%20Town`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'La Down Town')}>La Down Town - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/Marks%20Kitchen`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Marks Kitchen')}>Marks Kitchen - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/Le%20Vert%20Cafe`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Le Vert Cafe')}>Le Vert Cafe - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/The%20Burning%20Munch`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'The Burning Munch')}>The Burning Munch - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Johar%20Town/Cafe%20De%20Luna`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Johar Town', 'Cafe De Luna')}>Cafe De Luna - Johar Town</a></li>
                        </ul>
                    </div>
                    <div className="category">
                        <h4>PAKISTANI</h4>
                        <ul>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Karachi%20Hot%20N%20Spicy%20-%20DHA`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Karachi Hot N Spicy - DHA')}>Karachi Hot n Spicy - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Township/${encodeURIComponent('Bubbly Sajji Hareesa')}`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Township', 'Bubbly Sajji Hareesa')}>Bubbly Sajji Hareesa - Township</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/Jaidi`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Jaidi')}>Jaidi - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/Ice%20Land%20Gulberg%203`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Ice Land Gulberg 3')}>Ice Land - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/Fresh%20Tikka`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Fresh Tikka')}>Fresh Tikka - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Garden%20Town/Malang%20Jan%20Restaurant`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Garden Town', 'Malang Jan Restaurant')}>Malang Jan Restaurant - Garden Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/Biryani%20Master%20-Gulberg`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Biryani Master -Gulberg')}>Biryani Master - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Thalii%20Restaurant`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Thalii Restaurant')}>Thalii Restaurant - Dha</a></li>
                        </ul>
                    </div>
                    <div className="category">
                        <h4>BURGERS</h4>
                        <ul>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Zinger%20House`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Zinger House')}>Zinger House - Garden Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/Rb%20Clifton`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'Rb Clifton')}>Rb Clifton - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Johar%20Town/Mad%20Mighty%20%26%20Delicious`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Johar Town', 'Mad Mighty & Delicious')}>Mad Mighty & Delicious - Johar Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Barkat%20Market/Lasani%20Burger%20%26%20Steak`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Barkat Market', 'Lasani Burger & Steak')}>Lasani Burger & Steak - Barkat Market</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Breezy%20Bites`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Breezy Bites')}>Breezy Bites - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Food%20Land`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Food Land')}>Food Land - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Township/Fry%20Factory`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Township', 'Fry Factory')}>Fry Factory - Township</a></li>

                        </ul>
                    </div>
                    <div className="category">
                        <h4>PIZZA</h4>
                        <ul>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Pizza%20Town`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Pizza Town')}>Pizza Town - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Amigo%27s%20PIZZA%20HUT`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', "Amigo's PIZZA HUT")}>Amigo's Pizza - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg/M21%20Pizza`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Gulberg', 'M21 Pizza')}>M21 Pizza - Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Red%20Chilli`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Red Chilli')}>Red Chilli - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Dha/Hunger%20Ground`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Dha', 'Hunger Ground')}>Hunger Ground - Dha</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Wapda%20Town/Mad%20Chicken`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Wapda Town', 'Mad Chicken')}>Mad Chicken - Wapda Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Wapda%20Town/London%20Melt`} onClick={(e) => handleRestaurantClick(e, 'Lahore', 'Wapda Town', 'London Melt')}>London Melt - Wapda Town</a></li>

                        </ul>
                    </div>
                </div>
                {/* <div className="city-section">
                    <div className="city">
                        <a href="" onClick={() => handleCityClick('Lahore')}>Lahore</a>
                    </div>
                    <div className="city">
                        <a href="" onClick={() => handleCityClick('Karachi')}>Karachi</a>
                    </div>
                    <div className="city">
                        <a href="" onClick={() => handleCityClick('Islamabad')} >Islamabad</a>
                    </div>
                    <div className="city">
                        <a href="" onClick={() => handleCityClick('Rawalpindi')}>Rawalpindi</a>
                    </div>
                </div> */}
                <div className="picture-section">
                    <div className="category">
                        <h4>AREAS IN LAHORE</h4>
                        <ul>
                            <li><a href={`/restaurants/city/Lahore/area/DHA`} onClick={(e) => handleAreaClick(e, 'Lahore', 'DHA')}>DHA</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/Gulberg`} onClick={(e) => handleAreaClick(e, 'Lahore', 'Gulberg')}>Gulberg</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/JoharTown`} onClick={(e) => handleAreaClick(e, 'Lahore', 'JoharTown')}>Johar Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/BahriaTown`} onClick={(e) => handleAreaClick(e, 'Lahore', 'BahriaTown')}>Bahria Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/ModelTown`} onClick={(e) => handleAreaClick(e, 'Lahore', 'ModelTown')}>Model Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/FaisalTown`} onClick={(e) => handleAreaClick(e, 'Lahore', 'FaisalTown')}>Faisal Town</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/TownShip`} onClick={(e) => handleAreaClick(e, 'Lahore', 'TownShip')}>TownShip</a></li>
                            <li><a href={`/restaurants/city/Lahore/area/GardenTown`} onClick={(e) => handleAreaClick(e, 'Lahore', 'GardenTown')}>Garden Town</a></li>


                        </ul>
                    </div>
                    <div className="category">
                        <h4>GIFT & FLOWERS IN LAHORE </h4>
                        <ul>
                            <li><a href={`/restaurants/city/Lahore/Gift&Flowers`} onClick={(e) => handleGiftClick(e, 'Lahore', 'Gift&Flowers')}>Gift & Flowers</a></li>
                        </ul>
                    </div>
                    <div className="category">
                    <h4>OUR BLOGS </h4>
                        <ul>
                            <li><a href={`/blog/Online-Food-Delivery-in-Lahore`} onClick={(e) => handleBlogClick(e, 'Online-Food-Delivery-in-Lahore')}>Online Food Delivery in Lahore</a></li>
                            <li><a href={`/blog/Top-Cafes-in-Johar-Town-Lahore`} onClick={(e) => handleBlogClick(e, 'Top-Cafes-in-Johar-Town-Lahore')}>Top Cafes in Johar Town Lahore</a></li>
                            <li><a href={`/blog/Top-Rated-Restaurants-in-Bahria-Town-Lahore-for-Dining`} onClick={(e) => handleBlogClick(e, 'Top-Rated-Restaurants-in-Bahria-Town-Lahore-for-Dining')}>Top-Rated Restaurants in Bahria Town Lahore for Dining</a></li>
                        </ul>
                    </div>

                    <div className="category"></div>

                </div>
                <hr className="subfooter-hr" />
            </footer>
            <ToastContainer />
        </div>
    );
}

export default SubFooter;
