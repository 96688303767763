import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './faq-section.css';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const faqs = [
        { question: "What service are we offering?", answer: "ForunPoint is an online platform that provides food and gift services. We offer a wide selection of delicious meals and unique gifts, conveniently delivered to your doorstep. Whether you’re looking to enjoy a great meal at home or send a special gift to a loved one, ForunPoint has you covered." },
        { question: "What is the cancellation policy?", answer: "Users can cancel their order via the platform before it is accepted by the restaurant. Once the order is in progress, cancellation is not possible." },
        { question: "Can we order food or gifts through the website?", answer: "Yes, you can order food or gifts using the ForunPoint app, as well as the website." },
        { question: "What to do if I face a problem with my order?", answer: "You can directly contact our customer support team from our app and share any issue you’re facing with your order. Our team will instantly resolve queries to ensure a seamless experience. " },
        { question: "Can I use discount codes and coupons?", answer: "Yes, we are offering exclusive discount codes and coupons to both new and existing customers. Ensure that the discount code is valid for your specific location." },
        { question: "What is the estimated delivery time?", answer: "It varies on your location; however, you can expect your order to be delivered within 25 to 50 minutes." },

    ];

    const toggleAccordion = index => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <div className="faq-heading">
                <h2>Frequently<br />asked question</h2>
                <p>CONTACT US FOR MORE INFO</p>
            </div>
            <Accordion className="faq-accordion">
                {faqs.map((faq, index) => (
                    <AccordionItem key={index}>
                        <AccordionItemHeading onClick={() => toggleAccordion(index)}>
                            <AccordionItemButton>
                                <p className='faq-question'><span className="faq-index">{index + 1}</span>{faq.question}</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {openIndex === index && (
                                <div className="accordion-content">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default FAQ;
