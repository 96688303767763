import React from 'react'
// import './thirdpage.css'
import FpSucess from '../../../../assets/images/third.jpeg'




function thirdpage() {
    return (
        <div >
            <div className="firstpage">
                <div className="first-page-img">
                    <img src={FpSucess} alt="3rd" />
                </div>
                <div className="first-page-title">
                    <h1>ForunPoint's Blueprint for Success in 2024</h1>
                </div>
                <div className="first-page-content">
                    <p>

                        As we step into 2024, ForunPoint stands at the forefront of an exciting phase of growth and innovation. I am thrilled to outline our strategic execution plan for the year, focusing on empowering restaurants, expanding our reach, enhancing our organizational structure, and driving profitability. This article discusses the following topics:
                        <br />
                        <br />
                        <ul>
                            <li>Empowering Restaurants with Tailored Growth Opportunities <br />
                            </li>
                            <li>Strategic Partnerships and Fundraising for Aggressive Growth <br />
                            </li>
                            <li>Scaling Our Organizational Structure<br />
                            </li>
                            <li>Expands Horizons: Expand Ride-Share and Grocery Delivery Services for a New Era of Convenience <br />
                            </li>
                            <li> Driving Profitability <br />
                            </li>


                        </ul>
                    </p>

                    <h2>Empowering Restaurants with Tailored Growth Opportunities</h2>
                    <p>
                        Our primary focus in 2024 is to provide restaurants with unparalleled opportunities to grow their businesses. We will achieve this by offering structured assistance in boosting their visibility and sales, capitalizing our in-app features and social media marketing expertise.
                    </p>
                    <h2>Strategic Partnerships and Fundraising for Aggressive Growth</h2>
                    <p>
                        Recognizing the importance of collaboration and financial strength in achieving our ambitious goals, we will actively seek strategic partnerships and fundraising opportunities. These efforts will be aimed at not only expanding our operational capabilities but also at bringing innovative solutions to the market, further cementing ForunPoint’s position as a leader in the online food order and delivery sector.
                    </p>
                    <h2>Scaling Our Organizational Structure</h2>
                    <p>
                        As we grow, so will our team. In 2024, we plan to enhance our organizational structure by adding more resources crucial for the development and delivery of our products and services. This expansion will include hiring talents in technology, marketing, sales, customer service, and operations, ensuring that we have the right people to drive our vision forward.
                    </p>
                    <h2>Expands Horizons </h2>
                    <p>
                        Expand Ride-Share and Grocery Delivery Services for a New Era of Convenience.
                    </p>
                    <h2>Driving Profitability</h2>
                    <p>
                        Amidst these ambitious plans, our ultimate goal for 2024 is to drive ForunPoint towards profitability. This will be achieved through a combination of increased market penetration, enhanced operational efficiencies, and continuous innovation in our service offerings. We are committed to creating a sustainable business model that delivers value to our customers, partners, and stakeholders.
                    </p>
                    <h2>Conclusion </h2>
                    <p>
                        In conclusion, 2024 is set to be a year of dynamic growth and impactful initiatives for ForunPoint. With our clear vision and dedicated team, we are well-positioned to make significant strides in our journey toward becoming one of the leading name in online food order and delivery services, while also expanding into other key services like ride-sharing and grocery delivery.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default thirdpage
