import "./delivery-section.css";

const DeliverySection = () => {
  return (
    <div class="delivery-container">
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="delivery-card">
            <div class="card-body">
              <ul className="delivery-list">
                <li className="delivery-list-heading"><span className="list-title">Crave-worthy Delights Await!</span></li>
                <p className="delivery-content">
                Dive into Flavour Town with ForunPoint, your ultimate ticket to a culinary adventure! From spicy to creamy and downright delicious, we have covered you with our cuisine options. Isn’t it amazing?
                </p>
                <li><span className="list-title">Gift Happiness on a Platter</span></li>
                <p className="delivery-content">
                Why not share the joy? ForunPoint is a fantastic platform for gifting & flowers; sharing delicious meals with your loved ones has never been easier. Celebrate their special moments by sending them gifts to let them know you care with a sweet treat they’ll savor.
                </p>
                <li><span className="list-title">Voice Command Convenience</span></li>
                <p className="delivery-content">
                Do you have a hectic schedule? No worries! Voice ordering with us makes hunger satisfaction as simple as saying a few words. Just speak it and leave everything to us. It’s that easy!
                </p>
                <li><span className="list-title">Cravings? Just Tap and Go!</span></li>
                <p className="delivery-content">
                Are you looking for desi <b>restaurants in Pakistan</b>? Or do you have a specific craving in mind? Our smart search feature made it very easy for you to search for your favorite items from your favorite restaurants. With the tap of a few buttons, you are on your way to satisfying all your taste buds. Food has never been closer!
                </p>
                <li><span className="list-title">Feast Without the Guilt!</span></li>
                <p className="delivery-content">
                Why spend more when you can get more? Thanks to ForunPoint’s killer deals on the most popular <b>restaurants in Pakistan</b>; you can enjoy the food you’ve always craved without burning a hole in your pocket. Want the foods you enjoy while staying within your budget? Place your order now!
                </p>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="delivery-card">
            <div class="card-body">
              <img
                className="delivery-section-img"
                src="./images/deliveryimg.png"
                alt="Delivery"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliverySection;
