import React from 'react';
import mobile1 from '../../../assets/images/mobile1.png';
import mobile2 from '../../../assets/images/mobile2.png';
import appstore from '../../../assets/images/appstore-btn.png';
import playstore from '../../../assets/images/playstore-btn.png';
import './features.css';

function features() {
    return (
        <div className='features-page'>
            <div className="features-content">
                <img src={mobile1} className='mobile-image-1' alt="Mobile Image 1" />
                <ul className='content-list'>
                    <li className='feature-heading'><span className='feature-heading-text'>ForunPoint App</span></li>
                    <p className='feature-desc'>Welcome to the world of exciting flavours with the ForunPoint app! You know where to get it, whether cheesy pizza, juicy burgers, or spicy tenders, with prompt delivery at your service. Some cool features include gifting your faves, ordering by voice, and a straightforward search, making satisfying those cravings easier. Also, follow your orders in real time and get access to special promotions introduced by the best restaurants. Download ForunPoint today and enjoy the tasty foods that will be conveniently brought straight to your door.</p>
                    <div className='store-btns-wrapper'>
                    <a
                      href="https://apps.apple.com/us/app/forunpoint/id1630196197"
                      target="_blank"
                      rel="noreferrer"
                    >
                        <img src={appstore} className='store-btns' />
                      
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user"
                      target="_blank"
                      rel="noreferrer"
                    >
                        <img src={playstore} className='store-btns' />
                      
                    </a>
                    </div>
                    <br /><br />
                    <li className='feature-heading'><span className='feature-heading-text'>ForunPoint Partners</span></li>
                    <p className='feature-desc'>ForunPoint Partners will help you revolutionize your restaurant’s experience! Sleek and easy to use, our app takes only 3 minutes to set up and lets you monitor orders, sales and financial data on the go. Be updated with actual time order status, customer response, and notification. Join us as restaurants now and experience ForunPoint’s rich features. Welcome to your restaurant’s success story; just a click away!</p>
                    <div className='store-btns-wrapper'>
                    <a
                    href="https://apps.apple.com/us/app/d-vour-chef/id1541482615"
                    target="_blank"
                    rel="noreferrer"
                  > 
                        <img src={appstore} className='store-btns' />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.whetcloud.de_vour_chef"
                    target="_blank"
                    rel="noreferrer"
                  >
                   
                        <img src={playstore} className='store-btns' />
                  </a>
                    </div>
                    <br /><br />
                    <li className='feature-heading'><span className='feature-heading-text'>ForunPoint Rider</span></li>
                    <p className='feature-desc'>Sign up for the ForunPoint delivery squad and get to the next level! Our rider app always focuses on your security and enables effective communication. Be your boss and set your schedule, get paid what you deserve, and other cool things such as bonuses earned and performance reports. Now, you can download ForunPoint Rider and become one of the most successful delivery professionals for <b>food delivery Pakistan!</b></p>
                    <div className='store-btns-wrapper'>
                    <a
                      href="https://apps.apple.com/us/app/forunpoint-driver/id1630196763"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appstore} className='store-btns' />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.driver"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={playstore} className='store-btns' />
                    </a>
                        
                    </div>
                </ul>
                <img src={mobile2} className='mobile-image-2' alt="Mobile Image 2" />
            </div>
        </div>
    )
}

export default features
