// import React from 'react';
// import { useState, useEffect } from 'react';
// import Navbar from '../Navbar/Navbar';
// import './profile-page.css';
// import { getMe } from '../../shared/apis';
// function ProfilePage() {

//     const [user, setusers] = useState([]);

//     const init = async () => {
//         let userProfile = await getMe();
//         console.log(userProfile);
//         setusers(userProfile.data.user);
//     };


//     useEffect(() => {
//         init();

//     }, []);

//     return (
//         <>
//             <Navbar />
//             <div className='profile-page'>
//                 <div>
//                     <h2 className='profile-title'>Profile:</h2>
//                     <div>
//                         <div className='name-section'>
//                             <div>
//                                 <label htmlFor="FirstName" className='input-label'>First Name</label>
//                                 <br />
//                                 <input type="text" placeholder='First Name' className='name-field' value={user.firstName} />
//                             </div>
//                             <div>
//                                 <label htmlFor="LastName" className='input-label'>Last Name</label>
//                                 <br />
//                                 <input type="text" placeholder='Last Name' className='name-field' value={user.lastName} />
//                             </div>
//                         </div>
//                         <div>
//                             <label htmlFor="FirstName" className='input-label'>Phone Number</label>
//                             <br />
//                             <input type='text' placeholder='Phone Number' className='input-field' value={user.phoneNumber} />

//                         </div>
//                         <div>
//                             <label htmlFor="FirstName" className='input-label'>Email</label>
//                             <br />
//                             <input type="email" placeholder='JohinDoe@gmail.com' className='input-field user-email' disabled value={user.email} />
//                         </div>
//                         <div className='save-btn-section'>
//                             <button className='save-btn'>Save</button>
//                         </div>
//                     </div>
//                 </div>
import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import './profile-page.css';
import { getMe, updateUser, createPaymentMethod, attachPaymentMethodToCustomer, getpaymentMethod, detachPaymentMethod, updatePassword } from '../../shared/apis';
import { Modal } from 'react-responsive-modal';
import googleicon from "../../assets/images/google-icon.png";
import Spinner from 'react-bootstrap/Spinner';


function ProfilePage() {
    document.body.style.backgroundColor = "#E2E2E2";
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);


    const [cardDetails, setCardDetails] = useState({
        nameOnCard: '',
        cardNumber: '',
        expiryDate: '',
        cvc: ''
    });
    const [showFinalPayment, setShowFinalPayment] = useState(false);
    const [cardDetailsList, setCardDetailsList] = useState([]);
    // const fetchData = async () => {
    //     const userProfile = await getMe();
    //     setUser(userProfile.data.user);
    //     console.log(userProfile);

    //     const pm = await Promise.all(
    //         userProfile.data.user.paymentMethods.map(async (paymentMethod) => {
    //             const paymentMethodData = await getpaymentMethod(paymentMethod);
    //             return paymentMethodData;
    //         })
    //     );

    //     setPaymentMethods(pm);
    //     console.log(pm)
    // };
    const fetchData = async () => {
        setIsLoading(true); // Set isLoading to true before fetching data
        try {
            const userProfile = await getMe();
            setUser(userProfile.data.user);

            const pm = await Promise.all(
                userProfile.data.user.paymentMethods.map(async (paymentMethod) => {
                    const paymentMethodData = await getpaymentMethod(paymentMethod);
                    return paymentMethodData;
                })
            );

            setPaymentMethods(pm);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false); // Set isLoading to false after data fetching is complete
        }
    };


    useEffect(() => {
        fetchData();
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateUser(user._id, user);
            window.location.reload();
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const handlePaymentMethodClick = () => {
        setOpen(true);
    };


    // const handleAddCardSubmit = () => {
    //     setShowFinalPayment(true);
    //     onCloseModal2();
    //     setCardDetailsList([...cardDetailsList, { ...cardDetails }]);
    // };


    const onCloseModal2 = () => {
        setOpen2(false);
    };

    // const handleRemoveCard = (index) => {
    //     const updatedCardDetailsList = [...cardDetailsList];
    //     updatedCardDetailsList.splice(index, 1);
    //     setCardDetailsList(updatedCardDetailsList);
    //     if (updatedCardDetailsList.length === 0) {
    //         setShowFinalPayment(false);
    //     }
    // };

    const renderCardList = () => {

        return paymentMethods.map((pm, index) => (
            <div className="final-payment-method" key={index}>
                <img src="./images/genral-card.png" alt="" className='final-genral-card' />
                <h3 className='final-card-number'>************{pm?.paymentMethod.card?.last4}</h3>
                <button className='remove-button' onClick={OpenModal3}>Remove</button>
                <Modal open={open3} onClose={onCloseModal3} center styles={{
                    modal: {
                        width: '80%',
                        maxWidth: '400px',
                        height: '130px',
                        backgroundColor: '#E2E2E2'
                    },

                    overlay: {
                        background: 'rgba(0, 0, 0, 0.159)'
                    }
                }}>

                    <p className='remove-question'>Are You Sure You want to Remove?</p>
                    {/* <button onClick={() => removePayment(pm.paymentMethod.id)} className='yes-button'>Yes</button> */}
                    <button onClick={() => removePayment(pm.paymentMethod.id)} className={`yes-button  ${submitted ? 'disabled' : ''}`} disabled={submitted}>Yes</button>
                    <button onClick={onCloseModal3} className='no-button'>No</button>

                </Modal>
            </div>

        ));
    };


    const handleAddPaymentMethodClick = () => {
        setOpen2(true);
        onCloseModal();
        setSubmitting(false);
        setCardDetails({
            nameOnCard: '',
            cardNumber: '',
            expiryDate: '',
            cvc: ''
        });
    };

    const handlePaymentClick = (paymentMethod) => {
        setSelectedPaymentMethod(paymentMethod);
        handleAddPaymentMethodClick()
    };

    const handleCardDetailsChange = (e) => {
        const { name, value } = e.target;
        if (name === 'cardNumber') {
            // Limit card number to 16 digits
            setCardDetails({ ...cardDetails, [name]: value.slice(0, 16) });
        } else if (name === 'cvc') {
            // Limit CVC to 3 digits
            setCardDetails({ ...cardDetails, [name]: value.slice(0, 3) });
        } else {
            setCardDetails({ ...cardDetails, [name]: value });
        }
    };

    const addPyamentMethod = async () => {
        setSubmitting(true);
        setSubmitted(true);
        let card = {
            number: cardDetails.cardNumber,
            exp_month: cardDetails.expiryDate.split('-')[1],
            exp_year: cardDetails.expiryDate.split('-')[0],
            cvc: cardDetails.cvc

        };
        if (user.stripeCustomerId) {
            let pm = await createPaymentMethod(card);
            let paymentMethods = user.paymentMethods
            paymentMethods.push(pm.paymentMethod.id)
            // console.log(pm.paymentMethod.id);
            await attachPaymentMethodToCustomer({ paymentMethodId: pm.paymentMethod.id, customerId: user.stripeCustomerId });
            await updateUser(user._id, { paymentMethods: paymentMethods })
        }
        onCloseModal2();
        window.location.reload();
    }

    const removePayment = async (pmId) => {
        setSubmitted(true);
        let paymentMethods = user.paymentMethods
        paymentMethods = paymentMethods.filter(item => item !== pmId);
        await detachPaymentMethod(pmId);
        await updateUser(user._id, { paymentMethods: paymentMethods })
        window.location.reload();
    }

    const onCloseModal3 = () => {
        setOpen3(false);
    };

    const OpenModal3 = () => {
        setOpen3(true);
    }
    const handleSaveClick = async () => {
        if (!currentPassword || !newPassword) {
            setError('Please provide both current and new passwords.');
            setTimeout(() => setError(''), 2000);  // Clear error message after 2 seconds
            return;
        }

        setLoading(true);

        try {
            const response = await updatePassword({
                oldPassword: currentPassword,
                password: newPassword,
                email: user.email,
            });

            if (response.status.code === 200) {
                setSuccess('Password updated successfully.');
                setTimeout(() => setSuccess(''), 2000);  // Clear success message after 2 seconds
                setCurrentPassword('');
                setNewPassword('');
            } else {
                setError('Failed to update password. Please try again.');
                setTimeout(() => setError(''), 2000);  // Clear error message after 2 seconds
            }
        } catch (error) {
            setError('Failed to update password. Please try again.');
            setTimeout(() => setError(''), 2000);  // Clear error message after 2 seconds
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Navbar />
            <div className='profile-page'>
                <div>
                    <h2 className='profile-title'>Profile:</h2>
                    <div className='name-section'>
                        <div>
                            <label htmlFor="firstName" className='input-label'>First Name</label>
                            <br />
                            <input type="text" name="firstName" placeholder='First Name' className='name-field' value={user.firstName} onChange={handleChange} />
                        </div>
                        <div>
                            <label htmlFor="lastName" className='input-label'>Last Name</label>
                            <br />
                            <input type="text" name="lastName" placeholder='Last Name' className='name-field' value={user.lastName} onChange={handleChange} />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="phoneNumber" className='input-label'>Phone Number</label>
                        <br />
                        <input type='text' name="phoneNumber" placeholder='Phone Number' className='input-field' value={user.phoneNumber} onChange={handleChange} />
                    </div>
                    <div>
                        <label htmlFor="email" className='input-label'>Email</label>
                        <br />
                        <input type="email" name="email" placeholder='JohnDoe@gmail.com' className='input-field user-email' disabled value={user.email} />
                    </div>
                    <div className='save-btn-section'>
                        <button type="submit" className='save-btn' onClick={handleSubmit}>Save</button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <div className='name-section'>
                        <div>
                            <label htmlFor="firstName" className='input-label'>Current Password</label>
                            <br />
                            <input type="text" name="firstName" placeholder='Current Password' className='name-field' value={""} onChange={ } />
                        </div>
                        <div>
                            <label htmlFor="lastName" className='input-label'>New Password</label>
                            <br />
                            <input type="text" name="lastName" placeholder='New Password' className='name-field' value={""} onChange={ } />
                        </div>
                    </div>
                    <div className='save-btn-section'>
                        <button type="submit" className='save-btn' onClick={ }>Save</button>
                    </div> */}
                    {/* <div className='name-section'>
                        <div>
                            <label htmlFor="currentPassword" className='input-label'>Current Password</label>
                            <br />
                            <input
                                type="password"
                                name="currentPassword"
                                placeholder='Current Password'
                                className='name-field'
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="newPassword" className='input-label'>New Password</label>
                            <br />
                            <input
                                type="password"
                                name="newPassword"
                                placeholder='New Password'
                                className='name-field'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='save-btn-section'>
                        <button
                            type="submit"
                            className='save-btn'
                            onClick={handleSaveClick}
                            disabled={loading}
                            style={{ backgroundColor: loading ? '#d3d3d3' : '', cursor: loading ? 'not-allowed' : '' }}
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>} */}


                </div>

                {/* <div className='password-section'>
                    <h2 className='profile-title'>Password:</h2>
                    <div className='password-content'>
                        <div>
                            <label htmlFor="FirstName" className='input-label'>Current Password</label>
                            <br />
                            <input type="password" placeholder='Current Password' className='password-field' />
                        </div>
                        <div>
                            <label htmlFor="LastName" className='input-label'>New Password</label>
                            <br />
                            <input type="password" placeholder='New Password' className='password-field' />
                        </div>
                    </div>
                    <div className='save-btn-section'>
                        <button className='save-btn'>Save</button>
                    </div>
                </div> */}


                <div className="payment-method">
                    <img src="./images/payment_wallet.png" className='payment-wallet' alt="" />
                    <p className='payment-method-text'>Payment Methods:</p>
                </div>

                {isLoading ? (
                    <div className='m-5'>
                        <Spinner animation="border" variant="danger" />
                    </div>
                ) : (
                    renderCardList()
                )}



                <div className="add-payment-method" onClick={handlePaymentMethodClick}>
                    <img src="./images/payment-plus-icon.png" alt="" />
                    <p className='add-payment-method-text'>Add Payment Methods</p>
                </div>

                <div className="payment-method-dialog1">
                    <Modal open={open} onClose={onCloseModal} center styles={{
                        modal: {
                            width: '80%',
                            maxWidth: '350px',
                            height: '400px',
                            backgroundColor: '#E2E2E2'
                        },
                    }}>
                        <div className='select-payment-container'>
                            <img src="./images/Rectangle 122.png" alt="" />
                            <p>Select Payment Method</p>
                        </div>

                        <div className={`mastercard-container ${selectedPaymentMethod === 'mastercard' ? 'checked' : ''}`} onClick={() => handlePaymentClick('mastercard')}>
                            <img src="./images/genral-card.png" alt="" />
                            <input type="radio" className='mastercard-input' name="paymentMethod" checked={selectedPaymentMethod === 'mastercard'} />
                            <label className='genral-card'> Add a debit or credit card</label>
                        </div>

                        <div className='paypal-container'>
                            <img src="./images/Group 24.png" alt="" />
                            <label className='paypal-card'> Coming Soon...</label>
                        </div>

                    </Modal>
                    <Modal open={open2} onClose={onCloseModal2} center styles={{
                        modal: {
                            width: '80%',
                            maxWidth: '350px',
                            height: '400px',
                            backgroundColor: '#E2E2E2'
                        },
                    }}>

                        <div className='select-payment-container'>
                            <img src="./images/Rectangle 122.png" alt="" />
                            <p>Add Card Details</p>
                        </div>

                        {/* <div className="name-on-card">
                            <p>Name on Card</p>
                            <input type="text" name="nameOnCard" value={cardDetails.nameOnCard} onChange={handleCardDetailsChange} />
                        </div> */}
                        {/* onChange={(e) => { setCardNumber(e.target.value) }} */}
                        <div className='card-number-input'>
                            <p>Card Number</p>
                            <input type="number" name="cardNumber" value={cardDetails.cardNumber} onChange={handleCardDetailsChange} />
                        </div>

                        <div className='datetime-cvc-input'>
                            <div className='legends'>
                                <p className='mm-yy'>MM/YY</p>
                                <p>CVC</p>
                            </div>
                            <input type="month" name='expiryDate' value={cardDetails.expiryDate} onChange={handleCardDetailsChange} className='datetime-input' />
                            <input type="number" name='cvc' value={cardDetails.cvc} onChange={handleCardDetailsChange} className='payment-number-input' />
                        </div>
                        {/* <button className='payment-save-button' onClick={addPyamentMethod}>Submit</button> */}
                        <button className={`payment-save-button ${submitted ? 'disabled' : ''}`} onClick={addPyamentMethod} disabled={submitted}>
                            Submit
                        </button>
                    </Modal>
                </div>

                {/* <div className="login-with-section">
                    <h2 className='profile-title'>Login With:</h2>
                    <div className='login-with-material'>
                        <div className="login-material-content">
                            <img src={googleicon} className='material-img' alt='' />
                            <p className='social-name'>Google</p>
                        </div>
                        <button className='material-btn-disconnect'>Disconnect</button>
                    </div>
                    <div className='login-with-material'>
                        <div className="login-material-content">
                            <img src="./images/facebook-icon.png" className='material-img' />
                            <p className='social-name'>Facebook</p>
                        </div>
                        <button className='material-btn-connect'>Connect</button>
                    </div>
                    <div className='login-with-material'>
                        <div className="login-material-content">
                            <img src="./images/apple-icon.png" className='material-img' />
                            <p className='social-name'>Apple</p>
                        </div>
                        <button className='material-btn-connect'>Connect</button>
                    </div>
                </div> */}
            </div >
        </>
    )
}

export default ProfilePage
