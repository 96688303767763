import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { ReactMediaRecorder } from 'react-media-recorder';
import './record.css';
import { uploadVideo } from '../../shared/apis'; // Adjust the import path as needed

const VideoRecorder = ({ onVideoUpload }) => {
  const [isRecorded, setIsRecorded] = useState(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const countdownRef = useRef(null);

  const handleStopRecording = async (blobUrl) => {
    setIsRecorded(true);
    setMediaBlobUrl(blobUrl);

    // Convert blob URL to a file
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const file = new File([blob], 'recorded-video.webm', { type: 'video/webm' });

    // Upload file to AWS S3
    try {
      const formData = new FormData();
      formData.append('file', file);
      // console.log('form',formData)

      const uploadResponse = await uploadVideo(formData);
      const uploadedVideoUrl = uploadResponse.data.url;
      // console.log(uploadedVideoUrl)
      onVideoUpload(uploadedVideoUrl); // Send video URL to parent component
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  };

  const handleReRecord = () => {
    setIsRecorded(false);
    setMediaBlobUrl(null);
    setCountdown(null);
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
    }
  };

  const startCountdown = (stopRecording) => {
    let timeLeft = 10; // countdown time in seconds
    setCountdown(timeLeft);

    countdownRef.current = setInterval(() => {
      timeLeft -= 1;
      setCountdown(timeLeft);

      if (timeLeft <= 0) {
        clearInterval(countdownRef.current);
        countdownRef.current = null;
        stopRecording(); // Stop recording after countdown
      }
    }, 1000);
  };

  return (
    <div>
      <ReactMediaRecorder
        video
        onStop={(blobUrl) => handleStopRecording(blobUrl)} // Ensure this is handled after stop
        render={({ startRecording, stopRecording, mediaBlobUrl: blobUrl }) => (
          <>
            {!isRecorded ? (
              <div>
                <div className="webcamContainer">
                  <Webcam
                    audio={false}
                    mirrored={true}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ width: 1280, height: 720, facingMode: 'user' }}
                  />
                </div>
                <div className="buttonContainer">
                  <button
                    className="button"
                    type="button"
                    onClick={() => {
                      startRecording();
                      startCountdown(stopRecording);
                    }}
                  >
                    Start
                  </button>
                  <button
                    className="button"
                    onClick={() => {
                      stopRecording();
                    }}
                  >
                    {countdown !== null ? `Preview (${countdown})` : 'Stop'}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <video controls style={{ width: '100%' }}>
                  <source src={mediaBlobUrl} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
                <div className="buttonContainer">
                  <button className="button" onClick={handleReRecord}>
                    Re-Record
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default VideoRecorder;
