// import "./about-section.css";

// const AboutSection = () => {
//   return (
//     <>
//       <div className="container about-section" id="about">
//         <div className="row">
//           <div className="col-md-6 col-lg-5">
//             <div className="about-card">
//               <div className="card-body">
//                 <img
//                   className="about-section-img"
//                   src="./images/about01.png"
//                   alt=""
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 col-lg-6">
//             {/* <!-- Column 2 content --> */}
//             <div className="about-card-content">
//               <div className="card-body">
//                 <h2 className="about-section-heading">About ForunPoint</h2>
//                 <img
//                   src="./images/about-underline.png"
//                   alt=""
//                   className="underline"
//                 />
//                 &nbsp;
//                 <img
//                   src="./images/about-underline-s.png"
//                   alt=""
//                   className="underline"
//                 />
//                 &nbsp;
//                 <img
//                   src="./images/about-underline-xs.png"
//                   alt=""
//                   className="underline"
//                 />
//                 <p className="about-section-content">
//                 Welcome to ForunPoint, your gateway to exceptional dining experiences. Our platform is dedicated to bringing mouth-watering dishes from top restaurants right to your doorstep. With a focus on accessibility and convenience, ForunPoint offers a diverse range of culinary delights tailored to individual tastes.
//                   <br />
//                   <br /> What sets ForunPoint apart is our unparalleled search capability, allowing users to explore hundreds of unique and popular dishes based on country or dish reference. Whether you're craving authentic Mexican cuisine, comforting Italian pasta, or exotic Thai flavours, ForunPoint delivers.
//                   <br /> <br /> Experience the convenience of ordering delicious meals from your favourite restaurants directly from your mobile device with ForunPoint's online food ordering platform. With real-time order tracking and a seamless user experience, ForunPoint is here to satisfy your cravings and elevate your dining experience. Join us today and discover a world of culinary delights at your fingertips.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AboutSection;


import "./about-section.css";

const AboutSection = () => {
  return (
    <>
      <div className="about-section" id="about">
        <div className="row">
          <div className="col-md-6 col-lg-7 order-md-2">
            <div className="about-card-content">
              <div className="card-body">
                <h2 className="aboutus-heading">About Us</h2>
                <h2 className="about-section-heading">Forunpoint: Your Gateway to Joyful Deliveries!</h2>
                <div className="underline-sec">
                  <div className="underline1"></div>
                  &nbsp;
                  <div className="underline2"></div>
                  &nbsp;
                  <div className="underline3"></div>
                </div>
                <p className="about-section-content">
                  {/* ForunPoint is a US-based company that originated in Frisco, Texas. At ForunPoint, our mission is to completely revolutionize the delivery experience and bring happiness directly to our customer’s doorstep. We envision a future where anyone can seamlessly order food, flowers, and gifts for themselves or their loved ones. At ForunPoint, we value innovation, convenience, and ingenuity above all else. We inculcate these values in the development of our services to drive our business to unparalleled heights. We are excited to announce user-centric innovations like voice ordering, allowing users to send gifts from anyplace to their loved ones residing anywhere we provide our delivery services to, along with our video memo feature that cultivates the emotional connection between your family and friends. */}
                  Founded in the center of Frisco, Texas, ForunPoint has revolutionized how you connect with your family and friends. Imagine a world where you can order food, flowers, and gifts with a single tap on your smartphone. 
                  <br /><br />
                  {/* <br /> ForunPoint closely collaborates with our esteemed restaurant partners and provides them with the opportunity to broaden their audience and boost revenue. We offer restaurant owners numerous perks and benefits, like better incentives, low commission rates, device-less integration, and SaaS solutions. These perks provide our restaurant partners with a competitive advantage in the food industry. */}
                  We bring happiness to your doorstep through joyful moments by offering reliable Pakistani food delivery apps. We are all about innovation, convenience, and creativity, so we’re infusing these core values into every aspect of our services to take your experience to the next level. Get ready to be intrigued by our innovative approach.
                  <br />
                  {/* Our team of dedicated members works tirelessly to connect users with our amazing restaurant partners. Our company caters to foodies, restaurant owners, and riders and is currently operational in Lahore, Pakistan’s food capital, with plans to expand in the near future. So whether you're a restaurant owner, a prospective buyer, or a rider, ForunPoint has you completely covered. */}
                  It’s our pleasure to present groundbreaking features created especially for you! Voice ordering allows you to order gifts from any location at any time—no more hassles with the phone when it is time to make someone’s day. Moreover, our video memo feature helps convey warm words that help maintain relationships with friends and family.
                  <br />
                  We are not just a food delivery service; we are a community. We are in synergy with our valued restaurant partners, providing the right solutions to help them grow their businesses. Our partners get better commission rates, no device integration, and SaaS solutions that help them deserve a competitive edge in the food industry.
                  <br />
                  Our dedicated team is passionate about connecting you with the <b>best restaurants in Pakistan</b> —the food capital of Pakistan! Whether you are a food lover seeking great food, a restaurant proprietor needing business expansion, or a rider willing to deliver joy, ForunPoint is here for you.
                  <br />
                  <b>Join us on this exciting journey!</b> As we look forward to expanding our services, we’re committed to bringing your desires closer than ever.
                  <br />
                  <b>ForunPoint – Dream It, Tap It, Live It!</b>
                  {/* ForunPoint – Your Desire, One Tap Away! */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 order-md-1">
            <div className="about-card">
              <div className="card-body img-body">
                <img
                  className="about-section-img"
                  src="./images/about01.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
